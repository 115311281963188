<template>
    <div class="write-data">
        <div class="form-data subject">
            <div class="title">제목</div>
            <input type="text" placeholder="제목입력" class="value" v-model="title" @input="parentSetData('title', title)" maxlength="30"></input>
        </div>
        <div class="form-data contents">
            <div class="title">내용</div>
            <textarea placeholder="멘토끼리 소통해보세요!" class="value" v-model="content" @input="parentSetData('content', content)" maxlength="2000"></textarea>
        </div>
    </div>

</template>

<script>
    import EventBus from "@/utils/event-bus";
    import BoardValidator from "@/mixins/validators/BoardValidator";
    import util from "@/mixins/util";
    import {mapState, mapGetters} from "vuex";

    export default {
        name: "BoardWriteMentorInputLayout",
        mixins: [BoardValidator],
        inject: ['boardWriteSetData', 'boardParsingDetailData'],
        props: {
            boardData: {
                default: {}
            },
        },
        data() {
            return {
            }
        },
        computed: {
            ...mapState({
                UserInfo: state => state.userInfo.UserInfo,
            })
        },
        mounted() {
            this.initData();
        },
        methods: {
            initData() {
                if(!util.isEmpty(this.boardData)) {
                    this.title = this.boardParsingDetailData('title');
                    this.boardWriteSetData('title', this.title)
                    this.content = this.boardParsingDetailData('content');
                    this.boardWriteSetData('content', this.content)
                }
            },
            parentSetData(type, value) {
                // this[type] = value;
                this.boardWriteSetData(type, value);
            },
        },
    }
</script>

<style scoped>

</style>